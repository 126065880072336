<style lang="scss">
#client-location {
  .table-form-control {
    width: 100px !important;
    float: right;
  }
}
#client-service {
  input[type="file"] {
    display: none;
  }
}
.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background: #1bc5bd;
  color: white !important;
  border-radius: 3px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center input[type="checkbox"] {
  margin-right: 0.5rem;
}
</style>

<template>
  <div class="row" id="client-location">
    <div class="col-md-12">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="row">
              <div class="col-md-7">
                <legend><h6>Location Information</h6></legend>
                <hr />

                <div class="row">
                  <div class="form-group col-md-5">
                    <label for="name"
                      >Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      class="form-control"
                      v-model="formData.slug"
                      :class="errors['slug'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['slug']" class="text-danger">{{
                      errors["slug"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-7">
                    <label for="name"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      class="form-control"
                      v-model="formData.name"
                      :class="errors['name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['name']" class="text-danger">{{
                      errors["name"][0]
                    }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="description"
                    >Description <span class="text-danger">*</span></label
                  >
                  <textarea
                    name="description"
                    id=""
                    cols="30"
                    rows="1"
                    class="form-control"
                    v-model="formData.description"
                    :class="errors['description'] ? 'border border-danger' : ''"
                  ></textarea>
                  <span v-if="errors['description']" class="text-danger">{{
                    errors["description"][0]
                  }}</span>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="address"
                      >Address Line 1 <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address"
                      :class="errors['address'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address']" class="text-danger">{{
                      errors["address"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="address">Address Line 2 </label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address2"
                      :class="errors['address2'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address2']" class="text-danger">{{
                      errors["address2"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />

                  <div class="form-group col-md-6">
                    <label for="longitutde"
                      >State <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="state"
                      class="form-control"
                      v-model="formData.state"
                      :class="errors['state'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['state']" class="text-danger">{{
                      errors["state"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="city"
                      >City <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="city"
                      class="form-control"
                      v-model="formData.city_name"
                      :class="errors['city_name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['city_name']" class="text-danger">{{
                      errors["city_name"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="zipcode"
                      >Zip Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="zipcode"
                      v-mask="'#####'"
                      class="form-control"
                      v-model="formData.zipcode"
                      :class="errors['zipcode'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['zipcode']" class="text-danger">{{
                      errors["zipcode"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="client_z"
                      >Timezone<span class="text-danger">*</span></label
                    >

                    <select
                      v-model="formData.client_timezone"
                      class="form-control"
                      :class="
                        errors['client_timezone'] ? 'border border-danger' : ''
                      "
                    >
                      <option value="">Select time-zone</option>

                      <option
                        v-for="(item, index) in timeZones"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <span
                      v-if="errors['client_timezone']"
                      class="text-danger"
                      >{{ errors["client_timezone"][0] }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Contact Number</label>
                      <input
                        type="text"
                        v-mask="'(###)-###-####'"
                        class="form-control"
                        v-model="formData.phone_number"
                        name=""
                        id=""
                        :class="
                          errors['phone_number'] ? 'border border-danger' : ''
                        "
                      />
                      <span v-if="errors['phone_number']" class="text-danger">{{
                        errors["phone_number"][0]
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1"
                        >Payment Method</label
                      >
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        v-model="formData.payment_method"
                        :class="
                          errors['payment_method'] ? 'border border-danger' : ''
                        "
                      >
                        <option value="" selected>Select Payment Method</option>
                        <option value="cardpointe">CardPointe</option>
                        <option value="heartland" selected>Heartland</option>
                      </select>
                      <span
                        v-if="errors['payment_method']"
                        class="text-danger"
                        >{{ errors["payment_method"][0] }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <h6></h6>
                  <hr />

                  <div class="form-group col-md-2 mt-2">
                    <label>Active</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="is_active"
                        class="switch"
                        id="switch-normal"
                      />
                      <label for="switch-normal"></label>
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <div class="journey" v-if="showImage">
                      <canvas
                        id="imageCanvas"
                        ref="imageCanvas"
                        style="width: 240px"
                      ></canvas>
                      <!-- <image-crop
                        :image_base64="image_base64"
                        @base64Img="changeImage"
                      ></image-crop> -->
                    </div>

                    <label class="custom-file-upload">
                      <input
                        type="file"
                        id="imageLoader"
                        @change="updateCanvasImage($event, 1)"
                        hidden
                        :class="errors['image'] ? 'border border-danger' : ''"
                      />
                      <i class="fa fa-upload mr-2" style="color: white"></i>
                      Upload Image
                    </label>
                    <br />
                    <span v-if="errors['image']" class="text-danger">{{
                      errors["image"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-4">
                    <div class="journey" v-if="showIcon">
                      <canvas
                        id="iconcanvas"
                        ref="iconcanvas"
                        style="width: 240px"
                      ></canvas>
                      <!-- <image-crop
                        :image_base64="image_base64"
                        @base64Img="changeImage"
                      ></image-crop> -->
                    </div>

                    <label class="custom-file-upload">
                      <input
                        type="file"
                        id="imageLoader"
                        @change="updateCanvasImage($event, 2)"
                        hidden
                        :class="
                          errors['fav_icon'] ? 'border border-danger' : ''
                        "
                      />
                      <i class="fa fa-upload mr-2" style="color: white"></i>
                      Upload Icon
                    </label>
                    <br />
                    <span v-if="errors['fav_icon']" class="text-danger">{{
                      errors["fav_icon"][0]
                    }}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4 mt-2">
                    <label>Show Store Forward</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.show_store_forward"
                        class="switch"
                        id="switch-store"
                      />
                      <label for="switch-store"></label>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                        v-model="formData.copy_from"
                      />
                      <label class="form-check-label mt-3" for="gridCheck1">
                        Copy service from
                      </label>
                    </div>
                  </div>
                  <div class="col-md-5" v-if="formData.copy_from == true">
                    <div class="form-group">
                      <!-- <label for="">Copy service from</label> -->
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        v-model="formData.client_id"
                      >
                        <option value="null" selected>Select</option>
                        <option
                          v-for="(client, i) in getserviceProviders"
                          :key="i"
                          :value="client.id"
                        >
                          {{ client.name }}
                        </option>
                      </select>
                      <span v-if="errors['client_id']" class="text-danger">{{
                        errors["client_id"][0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <h6>Support Info</h6>
                <div class="form-group">
                  <supporteditor
                    v-model="formData.support_info"
                    api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                    :init="{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
                    }"
                  />
                </div>
              </div>
              <div
                class="col-md-5"
                style="border-left: 1px solid rgb(238, 238, 238)"
              >
                <div class="row">
                  <div class="col-md-12">
                    <legend>
                      <h6>Select Your Location</h6>
                      <span v-if="errors['long']" class="text-danger"
                        >Please select location</span
                      >
                    </legend>

                    <hr />
                    <keep-alive>
                      <GoogleMap
                        ref="map"
                        @getFullAddress="setFullAddress"
                        @dragPointer="setLocation"
                        @onSearchClick="setLocation"
                      ></GoogleMap>
                    </keep-alive>
                  </div>
                  <div class="col-md-12">
                    <legend><h6>User Information</h6></legend>
                    <hr />

                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for=""
                          >First Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          id=""
                          v-model="formData.first_name"
                          :class="
                            errors['first_name'] ? 'border border-danger' : ''
                          "
                        />
                        <span v-if="errors['first_name']" class="text-danger">{{
                          errors["first_name"][0]
                        }}</span>
                      </div>
                      <div class="form-group col-md-6">
                        <label for=""
                          >Last Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          id=""
                          v-model="formData.last_name"
                          :class="
                            errors['last_name'] ? 'border border-danger' : ''
                          "
                        />
                        <span v-if="errors['last_name']" class="text-danger">{{
                          errors["last_name"][0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for=""
                        >Password <span class="text-danger">*</span></label
                      >
                      <input
                        type="password"
                        name=""
                        class="form-control"
                        id=""
                        v-model="formData.password"
                        :class="
                          errors['password'] ? 'border border-danger' : ''
                        "
                      />
                      <span v-if="errors['password']" class="text-danger">{{
                        errors["password"][0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <label for=""
                        >Email <span class="text-danger">*</span></label
                      >
                      <input
                        type="email"
                        name=""
                        class="form-control"
                        id=""
                        v-model="formData.email"
                        :class="errors['email'] ? 'border border-danger' : ''"
                      />
                      <span v-if="errors['email']" class="text-danger">{{
                        errors["email"][0]
                      }}</span>
                    </div>

                    <div class="form-group">
                      <label for=""
                        >Notification Email
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        name=""
                        class="form-control"
                        id=""
                        v-model="formData.notification_email"
                        :class="
                          errors['notification_email']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                    </div>
                    <span
                      v-if="errors['notification_email']"
                      class="text-danger"
                      >{{ errors["notification_email"][0] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            ref="store_s"
            @click="store('S', 'store_s')"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('SAA', 'store_ssa')"
            ref="store_ssa"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import Editor from "@tinymce/tinymce-vue";
// import ImageCrop from "@/components/ImageCrop";

import {
  CREATE_SERVICE_PROVIDER,
  GET_SERVICE_PROVIDER_LIST
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "admin.dashboard"
        },
        {
          id: 2,
          title: "Service Provider",
          route: "admin.serviceprovider.index"
        },
        {
          id: 3,
          title: "Create Service Provider",
          route: ""
        }
      ],
      service_name: "",
      description: "",
      service_type: "",
      formData: {
        name: "",
        description: "",
        address: "",
        address2: "",
        state: "",
        city_name: "",
        zipcode: "",
        long: "",
        lat: "",
        client_timezone: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        slug: "",
        notification_email: "",
        payment_method: "",

        enable_on_site_payment: false,
        phone_number: "",
        confirm_password: "",
        password: "",
        client_id: "null",
        copy_from: false,
        fav_icon: "",
        show_store_forward: false,
        support_info: ""
      },
      service_table_data: [],
      is_active: true,

      showImage: false,
      image_base64: "",
      loading_data: true,
      errors: [],
      serviceError: "",
      selectAll: false,
      serchInput: "",
      timeZones: [],
      supporteditor: Editor,
      image_crop_base64: "",
      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showIcon: false
    };
  },
  computed: {
    ...mapGetters(["getserviceProviders"])
  },
  components: {
    GoogleMap,
    supporteditor: Editor
  },
  created() {
    this.timeZones = this.$moment.tz.names();
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit =
      this.$route.name == "admin.serviceprovider.edit" ? true : false;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "Edit | Service Provider";
    }
    if (this.getserviceProviders.length == 0) {
      this.getServiceProvider();
    }
  },
  watch: {},
  methods: {
    changeImage(val) {
      this.image_crop_base64 = val;
    },
    setFullAddress(data) {
      // console.log(data);
      this.formData.address = data;
    },
    getServiceProvider() {
      // console.log("adbk");
      this.loadingData = true;
      this.$store
        .dispatch(GET_SERVICE_PROVIDER_LIST)
        .then(res => {
          // console.log(res);
          if (res.code == 200) {
            this.url = res.url;
          }
          this.loadingData = false;
        })
        .catch(err => {
          this.loadingData = false;
          this.msg = err.error;
        });
    },

    setLocationDragged(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
    },
    setLocation(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
      this.formData.city_name = location.city.long_name;
      this.formData.address = location.formatted_address;
      this.formData.zipcode = location.postal_code.long_name;

      let state = location.state.short_name.toUpperCase();
      this.formData.client_timezone = "";

      this.formData.state = state === undefined ? "" : state;
    },
    goBack() {
      this.$router.push({
        name: "admin.serviceprovider.index"
      });
    },
    selectAllServices() {
      if (this.selectAll) {
        this.service_table_data.filter(service => {
          return (service.checked = true);
        });
      } else {
        this.service_table_data.filter(service => {
          return (service.checked = false);
        });
      }
    },

    validateEmail(value) {
      if (this.regEmail.test(value)) {
        this.errors["email"] = "";
      } else {
        this.errors["email"] = {
          0: "Please enter a valid email address"
        };
      }
    },
    updateEmail(e) {
      this.validateEmail(e.target.value);
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    setClientSlug(e) {
      var value = e.target.value;
      this.formData.slug = value.replace(/\s+/g, "").toLowerCase();
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    clearForm() {
      this.service_table_data = [];
      this.formData.name = "";
      this.formData.description = "";
      this.formData.address = "";
      this.formData.city_name = "";
      this.formData.zipcode = "";
      this.formData.long = "";
      this.formData.lat = "";
      this.formData.client_timezone = "";
      this.formData.payment_method = "";
      this.formData.first_name = "";
      this.formData.last_name = "";
      this.formData.email = "";
      this.formData.username = "";
      this.formData.slug = "";
      this.formData.address2 = "";
      this.formData.state = "";
      this.formData.notification_email = "";
      this.formData.image = "";
      this.formData.enable_on_site_payment = true;
      this.formData.password = "";
      this.formData.phone_number = "";
      this.formData.client_id = "null";
      this.formData.copy_from = false;
      this.formData.fav_icon = "";
      this.formData.support_info = "";
      this.is_active = true;
      this.formData.show_store_forward = false;
      this.showImage = false;
      this.loading_data = true;
      this.image_base64 = "";
      this.serviceError = "";
      this.errors = [];
    },

    store(status, ref) {
      // let img;
      // if (this.showImage) {
      //   img = this.image_crop_base64;
      // } else {
      //   img = "none";
      // }
      this.formData.slug = this.formData.slug.replace(/\s/g, "").toLowerCase();
      this.formData.image = this.image_base64;
      this.formData.user_name =
        this.formData.first_name + " " + this.formData.last_name;
      this.formData.username = this.formData.email;
      this.formData.notification_email =
        this.formData.notification_email != ""
          ? this.formData.notification_email.replace(" ", "")
          : "";
      if (status == "SAA") {
        this.$store
          .dispatch(CREATE_SERVICE_PROVIDER, {
            user_detail: this.formData,
            is_active: this.is_active
          })
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            this.clearForm();
          })
          .catch(err => {
            this.errors = err;
            this.closeBtnLoad(ref);
          });
      } else {
        this.$store
          .dispatch(CREATE_SERVICE_PROVIDER, {
            user_detail: this.formData,
            is_active: this.is_active
          })
          .then(data => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            this.$router.push({ name: "admin.serviceprovider.index" });
          })
          .catch(err => {
            this.errors = err;
            this.closeBtnLoad(ref);
          });
      }
    },

    updateCanvasImage(e, val) {
      if (val == 1) {
        this.showImage = true;
      } else {
        this.showIcon = true;
      }
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = e => {
        this.e = e;
        var img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img, val);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },
    drawCanvasImage(imageObj, val) {
      var canvas = "";
      if (val == 1) {
        canvas = this.$refs.imageCanvas;
      } else {
        canvas = this.$refs.iconcanvas;
      }
      canvas.width = 240;
      canvas.height = (100 * imageObj.height) / imageObj.width;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0,
        240,
        (100 * imageObj.height) / imageObj.width
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      if (val == 1) {
        this.image_base64 = base64;
      } else {
        this.formData.fav_icon = base64;
      }
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cropper {
  height: 600px;
  background: #ddd;
}
</style>
